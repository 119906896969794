import React from 'react';
import PropTypes from 'prop-types';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';

import SiteHeader from '@oup/shared-front-end/src/components/SiteHeader/SiteHeader.js';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_HELP_CIRCLE } from '@oup/shared-front-end/src/svg/oup/index';
import styles from '@oup/shared-front-end/src/components/SiteHeader/SiteHeader.scss';

import RegisterWizardFooter from './RegisterWizardFooter.js';

import withLocalizedContent from '../../language/withLocalizedContent';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import useMediaQuery from '../../utils/mediaQuery.js';
import FirstAndLastNameStep from './FirstLastNameStep.js';

function RegisterWizard({ localizedContent: { registrationPage: content } }) {
  const isMobile = useMediaQuery('(max-width: 920px)');

  return (
    <div data-testid="ONBOARDING_WIZARD_CONTAINER">
      <Wizard
        header={
          <SiteHeader
            buttons={[
              {
                type: Button,
                props: {
                  variant: 'filled',
                  icon: { component: <ICON_HELP_CIRCLE className={styles.helpIconLoggedOut} /> },
                  text: !isMobile ? content.support_heading_text : '',
                  onClick: () => window.open(HubLayoutConstants.HELP_AND_SUPPORT_URL, '_blank'),
                  className: !isMobile ? styles.helpButtonLarge : styles.helpButtonSmall
                }
              }
            ]}
            loggedIn={false}
            secondaryText={content.secondary_text}
          />
        }
        footer={<RegisterWizardFooter />}
      >
        <FirstAndLastNameStep />
        <div>Email address</div>
        <div>Password</div>
        <div>Accept terms and conditions</div>
        <div>Registration complete</div>
      </Wizard>
    </div>
  );
}

RegisterWizard.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('registrationPage')(RegisterWizard);
