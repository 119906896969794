import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import withLocalizedContent from '../../language/withLocalizedContent';
import RegisterWizard from '../../components/RegisterWizard/RegisterWizard.js';

import styles from './RegisterPageWizard.scss';

function RegisterPageWizard({ userId, localizedContent: { registrationPage: content } }) {
  const history = useHistory();

  if (userId) {
    history.push('/');
  }

  return (
    <div className={styles.register}>
      <Helmet title={content?.pageTitle} titleTemplate="%s | Oxford University Press" />
      <RegisterWizard />
    </div>
  );
}

RegisterPageWizard.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userId: PropTypes.string
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(state => ({
    userId: state.identity.userId
  }))
)(RegisterPageWizard);
