import React from 'react';
import PropTypes from 'prop-types';

import { ICON_LEFT } from '@oup/shared-front-end/src/svg/oup/index.js';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import withLocalizedContent from '../../language/withLocalizedContent';

function RegisterWizardFooter({ localizedContent: { registrationPage: content } }) {
  const { step, nextStep, prevStep, isPrimaryButtonDisabled } = useWizard();

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={content.register_step_button_text}
      primaryButtonAttributes={{
        disabled: isPrimaryButtonDisabled,
        dataAttributes: {
          testid: 'REGISTER_WIZARD_NEXT_BUTTON'
        }
      }}
      secondaryButtonLabel={step !== 0 ? content.register_prev_step_button_text : ''}
      secondaryButtonAttributes={{
        dataAttributes: {
          testid: 'REGISTER_WIZARD_BACK_BUTTON'
        },
        icon: {
          placement: 'left',
          component: <ICON_LEFT />
        }
      }}
      secondaryButtonAction={prevStep}
    />
  );
}

RegisterWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('registrationPage')(RegisterWizardFooter);
