import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { HUB_SUPPORT_PLACEMENT_TESTS_URL } from '@oup/shared-node-browser/constants';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import DataRefresher from '../../../components/DataRefresher/DataRefresher';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import {
  HubIllustrationAltText,
  HubIllustrationConstants,
  REFRESH_PLACEMENT_TESTS_INTERVAL
} from '../../../globals/hubConstants';
import { openPlacementTestOnboardingWizard } from '../../../redux/actions/hubUi';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { loadPlacementTestsRequest } from '../../../redux/actions/placementTests';
import HubFilterBar from '../../../components/HubFilterBar/HubFilterBar';
import { placementTestFilterOptions, placementTestSortOptions } from '../../../globals/hubSearchFilter';
import HubListHeader from '../../HubListPage/HubListHeader';
import userRoles from '../../../globals/userRoles';
import PlacementTestItem from './PlacementTest';
import styles from './OrganizationPlacementTests.scss';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import colors from '../../../globals/colors';
import HubFilterBarSkeleton from '../../../components/HubFilterBar/HubFilterBarSkeleton';
import withLocalizedContent from '../../../language/withLocalizedContent';
import calcDropdownPosition from '../Utils/calcDropdownPosition';
import getOptProductsCatalogueUrl from '../../../utils/opt/getOptProductsCatalogueUrl';
import redirectToUrl from '../../../utils/redirectToUrl';

const instance = 'orgPlacementTests';
function OrganizationPlacementTests(props) {
  const {
    arePlacementTestsLoading,
    organization,
    openOnboardingWizardAction,
    placementTests,
    userRole,
    breakpoint,
    isDataUpdated,
    getPlacementTestsAction,
    orgId,
    testResultsFileLoading,
    localizedContent: { hubGlossary: hubContent, placementTests: placementTestsContent }
  } = props;
  const [filters, setFilters] = useState({
    DRAFT: true,
    ACTIVE: true,
    UPCOMING: true,
    COMPLETED: true
  });
  const [sort, setSort] = useState('testEndDate:asc');
  const orgTitle = `${organization.name} | ${hubContent.org_menu_placementTests}`;
  const allFiltersUnchecked = Object.values(filters).every(item => item === false);
  const allFiltersChecked = Object.values(filters).every(item => item === true);
  const noPlacementTests = Object.values(placementTests).every(test => test.length === 0);
  const loadResetFilters = !arePlacementTestsLoading && noPlacementTests && !allFiltersUnchecked && !allFiltersChecked;
  const loadNoItemsFound = !arePlacementTestsLoading && noPlacementTests && (allFiltersChecked || allFiltersUnchecked);
  const statusOrder = ['DRAFT', 'ACTIVE_UPCOMING', 'COMPLETED'];

  const getFilters = changedFilters => Object.keys(changedFilters).filter(key => changedFilters[key]);

  const getPlacementTests = (changedFilters, changedSort) => {
    const payload = {
      orgId,
      status: getFilters(changedFilters),
      sortBy: changedSort,
      sortDraftsByName: true
    };
    getPlacementTestsAction(payload);
  };

  const createPlacementTestSessionButton = {
    text: hubContent.organization_page_create_placement_test_session,
    icon: GLYPHS.ICON_PLUS,
    action: openOnboardingWizardAction
  };

  const resetPlacementTestsButton = {
    text: hubContent.organization_page_filters_reset,
    icon: GLYPHS.ICON_REFRESH,
    action: () => getPlacementTests(filters, sort)
  };

  const purchaseLicencesButton = {
    text: hubContent.organization_page_purchase_licences,
    icon: GLYPHS.ICON_SHOPPING_CART,
    action: () => {
      const placementProductsUrl = getOptProductsCatalogueUrl(organization?.customId);
      redirectToUrl(placementProductsUrl, true);
    }
  };

  const learnMoreLink = {
    text: hubContent.organization_page_learn_more_about_placement_tests,
    link: HUB_SUPPORT_PLACEMENT_TESTS_URL,
    position: 'bottom'
  };
  const customFilterComponent = <HubFilterBarSkeleton />;
  const getStatusName = status => {
    switch (status) {
      case 'DRAFT':
        return hubContent.draft_placement_tests;
      case 'ACTIVE_UPCOMING':
        return hubContent.activeAndUpcomingPlacementTestsLabel;
      case 'COMPLETED':
        return hubContent.completed_placement_tests;
      default:
        return '';
    }
  };

  useEffect(() => {
    getPlacementTests(filters, sort);
    const timer = setInterval(() => {
      getPlacementTests(filters, sort);
    }, REFRESH_PLACEMENT_TESTS_INTERVAL);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (isDataUpdated) getPlacementTests(filters, sort);
  }, [isDataUpdated]);
  return (
    <div>
      <Helmet title={orgTitle} />
      <div className={styles.dataRefresherContainer}>
        <DataRefresher
          loading={arePlacementTestsLoading}
          refreshData={() => getPlacementTests(filters, sort)}
          showLabel={false}
        />
        <TextLink to={hubContent.help_and_support_link_to} target="_blank">
          {placementTestsContent.placement_test_cerf_competency_guide}
        </TextLink>
      </div>
      <div>
        <HubFilterBar
          idPrefix={instance}
          filterButtonText={hubContent.status_button}
          overlayLabelText={hubContent.status_button}
          breakpoint={breakpoint}
          filterOptions={placementTestFilterOptions(
            instance,
            [filters.DRAFT, filters.ACTIVE, filters.UPCOMING, filters.COMPLETED],
            (filterName, value) => {
              const newSearchFilters = { ...filters };
              newSearchFilters[filterName] = value;
              setFilters(newSearchFilters);
              getPlacementTests(newSearchFilters, sort);
            },
            hubContent
          )}
          sortOnChange={newSort => {
            setSort(newSort[0]);
            getPlacementTests(filters, sort);
          }}
          sortOptions={placementTestSortOptions(instance, sort, hubContent)}
          ariaControls={`${instance}-filterButton-menu`}
          sortValue={sort}
        />
        {arePlacementTestsLoading || testResultsFileLoading ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
            customEnhancedComponent={customFilterComponent}
          />
        ) : (
          statusOrder.map((statusGroupKey, index) => {
            const testsToRender = placementTests[statusGroupKey];
            if (testsToRender && testsToRender.length > 0) {
              return (
                <div key={index}>
                  <div data-testid="HubPlacementTestsListHeader">
                    <HubListHeader
                      item={{
                        name: getStatusName(statusGroupKey),
                        status: userRole !== userRoles.LEARNER ? hubContent.placement_test_date_created : '',
                        buttonText: userRole !== userRoles.LEARNER ? hubContent.add_placement_test_button : '',
                        action: openOnboardingWizardAction,
                        dataAttributes: { testId: 'ONBOARDING_WIZARD_PLACEMENT_TEST_SESSION_OPEN_WIZARD_BUTTON' }
                      }}
                      showCheckboxHeader={false}
                      hasHeaderButtonAccess={userRole !== userRoles.LEARNER}
                      customClassName="containerWithNoMargin"
                      isPlacementTest
                    />
                  </div>
                  <ul data-testid="placementTestsList">
                    {testsToRender.map((test, itemIndex) => (
                      <PlacementTestItem
                        key={test._id}
                        test={test}
                        userRole={userRole}
                        id={test._id}
                        customOrgId={organization.customId}
                        dropdownTop={calcDropdownPosition(testsToRender, itemIndex)}
                        isPlacementTestsPage
                      />
                    ))}
                  </ul>
                  <hr className={styles.separator} />
                </div>
              );
            }
            return null;
          })
        )}
      </div>
      {loadNoItemsFound && (
        <HubEmptyStateRestyle
          iconSrc={HubIllustrationConstants.LEARNING}
          title={hubContent.organization_page_filters_empty_state_title_opt}
          btnFilledBase={createPlacementTestSessionButton}
          btnOutlineBase={purchaseLicencesButton}
          link={learnMoreLink}
          buttonTestHook="ONBOARDING_WIZARD_PLACEMENT_TEST_SESSION_OPEN_WIZARD_BUTTON"
        />
      )}
      {loadResetFilters && (
        <HubEmptyStateRestyle
          iconSrc={HubIllustrationConstants.SEARCH}
          iconAlt={HubIllustrationAltText.SEARCH}
          title={hubContent.organization_page_filters_empty_state_title}
          bodyText={hubContent.organization_page_filters_empty_state_subtitle}
          btnFilledBase={resetPlacementTestsButton}
          btnOutlineBase={{
            isPrimaryButton: true,
            text: hubContent.add_placement_test,
            icon: GLYPHS.ICON_PLUS,
            action: openOnboardingWizardAction
          }}
        />
      )}
    </div>
  );
}

OrganizationPlacementTests.propTypes = {
  arePlacementTestsLoading: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  openOnboardingWizardAction: PropTypes.func,
  placementTests: PropTypes.object,
  getPlacementTestsAction: PropTypes.func,
  orgId: PropTypes.string,
  userRole: PropTypes.string,
  breakpoint: PropTypes.bool,
  isDataUpdated: PropTypes.bool,
  localizedContent: PropTypes.object,
  testResultsFileLoading: PropTypes.bool
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({
      organisations,
      loadPlacementTestsReducer: { placementTests, loading: arePlacementTestsLoading, refreshData },
      loadPlacementTestResultsReducer: { loading: testResultsFileLoading },
      identity
    }) => ({
      organization: organisations.data[identity.currentOrganisationId || ''] || {},
      placementTests,
      arePlacementTestsLoading,
      testResultsFileLoading,
      userRole: identity.role,
      isDataUpdated: refreshData
    }),
    {
      openOnboardingWizardAction: openPlacementTestOnboardingWizard,
      getPlacementTestsAction: loadPlacementTestsRequest
    }
  )
)(OrganizationPlacementTests);
